<template>
  <b-overlay
      :show="overlay"
      rounded="sm"
  >
    <!--  Picture modal  -->
    <b-modal
        id="picture-modal"
        ok-variant="primary"
        title="Picture"
        ok-title="Download"
        cancel-title="Dismiss"
        centered
        @ok="downloadPicture"
    >
      <b-row>
        <b-col
            cols="12"
            class="d-flex align-items-center justify-content-center mb-1 mb-md-0"
        >
          <img style="max-width:100%;width: max-content;min-height: 30rem" :src="baseUrl+picture"/>
        </b-col>
      </b-row>
    </b-modal>
    <!--  Picture modal  -->

    <!--  Picture modal  -->
    <button class="d-none" ref="modalBtn" v-b-modal.player-modal></button>
    <b-modal
        id="player-modal"
        title="Live"
        cancel-title="Dismiss"
        centered
        size="lg"
    >
      <b-row>
        <b-col
            v-if="video"
            cols="12"
            class="d-flex align-items-center justify-content-center mb-1 mb-md-0"
        >
          <iframe
              allowfullscreen
              style="width: 100%;min-height: 30rem"
              webkitallowfullscreen="true"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen="true"
              mozallowfullscreen="true"
              :src="video.playerUrl">
          </iframe>
        </b-col>
      </b-row>
    </b-modal>
    <!--  Picture modal  -->

    <!--  Player modal  -->
    <b-modal
        id="picture-modal"
        ok-variant="primary"
        title="Picture"
        ok-title="Download"
        cancel-title="Dismiss"
        centered
        @ok="downloadPicture"
    >
      <b-row>
        <b-col
            cols="12"
            class="d-flex align-items-center justify-content-center mb-1 mb-md-0"
        >
          <img style="max-width:100%;width: max-content;min-height: 30rem" :src="baseUrl+picture"/>
        </b-col>
      </b-row>
    </b-modal>
    <!--  Player modal  -->
    <div v-if="order">
      <!-- Table Container Card -->
      <b-card
          no-body
          class=""
      >
        <div class="m-2">
          <!-- Table Top -->
          <b-row>
            <b-col
                cols="12"
                md="6"
                class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
            >
              <h2>Order items</h2>
            </b-col>
          </b-row>
        </div>

        <b-table
            ref="refUserListTable"
            class="position-relative"
            :items="order.orderItems"
            responsive
            :fields="myTableColumnsSubOrders"
            primary-key="id"
            show-empty
            bordered
            striped
            empty-text="Nothing found!"
        >

          <!-- Column: productInfo -->
          <template #cell(productInfo)="data">
            <span>
               {{ data.item.productInfo.title }}
            </span>
          </template>

          <!-- Column: amount -->
          <template #cell(amount)="data">
            <span>
               {{ makePrettyPrice(data.item.amount) }}
            </span>
          </template>

        </b-table>
      </b-card>

      <b-card
          no-body
          class="mt-3"
      >
        <div class="m-2" style="overflow-x: auto">
          <!-- Table Top -->
          <b-row>
            <b-col
                cols="12"
                class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
            >
              <h2>Milestones</h2>
            </b-col>
            <b-col
                cols="12"
                class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
            >
              <!--     Stepper      -->
              <div class="stepwizard col-md-offset-3 w-100">
                <div class="stepwizard-row setup-panel">
                  <div style="min-width:10rem" class="stepwizard-step">
                    <span class="btn btn-primary btn-circle">1</span>
                    <p class="text-primary">Pre order</p>
                  </div>
                  <div style="min-width:10rem" class="stepwizard-step text-muted">
                    <span :class="[btnChecker(1)]" class="btn btn-circle" disabled="disabled">2</span>
                    <p :class="[textChecker(1)]">Pending</p>
                  </div>
                  <div style="min-width:10rem" class="stepwizard-step text-muted">
                    <span :class="[btnChecker(2)]" class="btn btn-circle" disabled="disabled">3</span>
                    <p :class="[textChecker(2)]">Waiting for admin</p>
                  </div>
                  <div style="min-width:10rem" class="stepwizard-step text-muted">
                    <span :class="[btnChecker(4)]" class="btn btn-circle" disabled="disabled">4</span>
                    <p :class="[textChecker(4)]">Paid</p>
                  </div>
                  <div style="min-width:10rem" class="stepwizard-step text-muted">
                    <span :class="[btnChecker(5)]" class="btn btn-circle" disabled="disabled">5</span>
                    <p :class="[textChecker(5)]">Sorting</p>
                  </div>
                  <div style="min-width:10rem" class="stepwizard-step text-muted">
                    <span :class="[btnChecker(6)]" class="btn btn-circle" disabled="disabled">6</span>
                    <p :class="[textChecker(6)]">Packeting</p>
                  </div>
                  <div style="min-width:10rem" class="stepwizard-step text-muted">
                    <span :class="[btnChecker(7)]" class="btn btn-circle" disabled="disabled">7</span>
                    <p :class="[textChecker(7)]">Preparing</p>
                  </div>
                  <div style="min-width:10rem" class="stepwizard-step text-muted">
                    <span :class="[btnChecker(8)]" class="btn btn-circle" disabled="disabled">8</span>
                    <p :class="[textChecker(8)]">Shipping from origin to destination</p>
                  </div>
                  <div style="min-width:10rem" class="stepwizard-step text-muted">
                    <span :class="[btnChecker(9)]" class="btn btn-circle" disabled="disabled">9</span>
                    <p :class="[textChecker(9)]">Shipping completed</p>
                  </div>
                  <div style="min-width:10rem" class="stepwizard-step text-muted">
                    <span :class="[btnChecker(10)]" class="btn btn-circle" disabled="disabled">10</span>
                    <p :class="[textChecker(10)]">Order closed</p>
                  </div>
                </div>
              </div>
              <!--     Stepper      -->
            </b-col>
          </b-row>
        </div>
      </b-card>

      <b-card v-if="selectedStep === 1">
        <b-col
            cols="12"
            class=""
        >
          <b-row>
            <h4 class="col-12">Please insert your payment information</h4>

            <b-col cols="12" md="6">
              <template>
                <p class="my-1">Payment picture</p>
                <b-avatar
                    ref="previewEl"
                    :src="base64ImageSrc"
                    class="cursor-pointer"
                    rounded
                    size="150px"
                    @click.native="$refs.refInputEl.click()"
                >
                  <feather-icon v-if="base64ImageSrc === null" icon="PlusSquareIcon" size="100"/>
                </b-avatar>
              </template>
              <b-button
                  class="mt-2 mb-sm-0 mr-2 col-12 col-md-5 d-none"
                  variant="warning"
                  @click="$refs.refInputEl.click()"
              >
                <input
                    ref="refInputEl"
                    accept=".jpg,.jpeg,.png,.svg"
                    class="d-none"
                    type="file"
                    @input="setCover($event)"
                >
              </b-button>
            </b-col>

            <b-col cols="12">
              <b-button
                  :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                  class="col-12 col-md-2 mt-2"
                  variant="primary"
                  @click="sendPaymentDetailByCustomer"
              >
                Save
              </b-button>
            </b-col>
          </b-row>
        </b-col>
      </b-card>

      <!--  Attachments    -->
      <b-card no-body>
        <b-col
            cols="12"
            class=""
        >
          <div class="my-2">
            <!-- Table Top -->
            <b-row>
              <b-col
                  cols="12"
                  md="6"
                  class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
              >
                <h2>Attachments</h2>
              </b-col>
            </b-row>
          </div>
          <b-table
              ref="refUserListTable"
              class="position-relative"
              :items="order.attachments"
              responsive
              :fields="myTableColumnsSubOrders2"
              primary-key="id"
              show-empty
              bordered
              striped
              empty-text="Nothing found!"
          >
            <template #cell(orderStatus)="data">
              <span v-if="data.item.orderStatus === 1">Pending</span>
              <span v-if="data.item.orderStatus === 2">Waiting for admin</span>
              <span v-if="data.item.orderStatus === 3">Payment in advanced</span>
              <span v-if="data.item.orderStatus === 4">Payed</span>
              <span v-if="data.item.orderStatus === 5">Sorting</span>
              <span v-if="data.item.orderStatus === 6">Packeting</span>
              <span v-if="data.item.orderStatus === 7">Preparing</span>
              <span v-if="data.item.orderStatus === 8">Shipping from origin to destination</span>
              <span v-if="data.item.orderStatus === 9">Shipping completed</span>
              <span v-if="data.item.orderStatus === 10">Order closed</span>
            </template>
            <template #cell(files)="data">
              <div class="w-100 d-flex justify-content-start align-items-center">
                <img @click="picture = file.imageUrl" v-b-modal.picture-modal class="m-1" v-for="file in data.item.files" style="width: 5rem;max-height: 5rem" :src="baseUrl+file.imageUrl" alt="">
              </div>
            </template>
          </b-table>
        </b-col>
      </b-card>
      <!--  Attachments    -->

      <!--  Live    -->
      <b-card v-if="selectedStep === 5 || selectedStep === 6">
        <b-col
            cols="12"
            class=""
        >
          <b-row>
            <h4 class="col-12 mb-2">Live</h4>
            <b-col cols="12">
              <b-button
                  @click="checkLive"
                  variant="outline-primary"
              >
                <feather-icon
                    icon="VideoIcon"
                    class="mr-50"
                />
                <span class="align-middle">Play live stream</span>
              </b-button>
            </b-col>
          </b-row>
        </b-col>
      </b-card>
      <!--  Live    -->

      <!--  GPS    -->
      <b-card v-if="selectedStep === 8">
        <b-col
            cols="12"
            class=""
        >
          <b-row>
            <h4 class="col-12 mb-2">GPS tracking</h4>
            <b-col cols="12">
              <b-button
                  variant="outline-primary"
              >
                <feather-icon
                    icon="CompassIcon"
                    class="mr-50"
                />
                <span class="align-middle">Show live tracking</span>
              </b-button>
            </b-col>
          </b-row>
        </b-col>
      </b-card>
      <!--  GPS    -->

      <b-card v-if="selectedStep === 10">
        <b-col cols="12" class="">
          <b-row>
            <b-col cols="12">
              <b-alert
                  variant="success"
                  show
              >
                <h4 class="alert-heading">
                  Congratulations
                </h4>
                <div class="alert-body">
                  <span>This order has been completed!</span>
                </div>
              </b-alert>
            </b-col>
          </b-row>
        </b-col>
      </b-card>
    </div>
  </b-overlay>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination, BOverlay,BFormTextarea,BFormGroup,BAlert
} from 'bootstrap-vue'
import vSelect from "vue-select";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import {
  LiveStreamGetOrderLiveStreamPlayerLink,
  OrderGetRequest,
  OrderPaymentDoneByUserMilestone
} from "@/libs/Api/Order";
import Helper from "@/libs/Helper";
export default {
  title:"Order info",
  name: "OrderInfo",
  data(){
    return{
      order:null,
      overlay:false,
      baseUrl:Helper.baseUrl,
      orderId:this.$route.params.id,
      myTableColumnsSubOrders:[
        { key: 'name' ,label :'Product'},
        { key: 'quantity' ,label :'Quantity'},
        { key: 'unitName' ,label :'Unit'},
        { key: 'unitPrice' ,label :'Unit Price'},
        { key: 'finalPrice' ,label :'Final Price'},
        { key: 'sumPrice' ,label :'Sum Price'},
        { key: 'offPrice' ,label :'Off Price'},
        { key: 'currencyTitle' ,label :'Currency'},
      ],
      myTableColumnsSubOrders2:[
        { key: 'orderStatus' ,label :'orderStatus'},
        { key: 'files' ,label :'File'},
      ],
      selectedStep : 0,
      paymentDetailByCustomer:{
        OrderId:this.$route.params.id,
        Description:''
      },
      base64ImageSrc:null,
      picture:null,
      video:null,
    }
  },
  async created(){
    try{
      await this.getOrder();
    }catch (e){
      console.log(e)
    }finally {
      this.selectedStep = this.order.orderStatus;
    }
  },
  methods:{
    async getOrder(){
      let _this = this;
      _this.overlay = true;

      let orderGetRequest = new OrderGetRequest(_this);
      orderGetRequest.setParams({id:_this.orderId});
      await orderGetRequest.fetch(function (content){
        _this.overlay = false;
        _this.order = content.data;
      },function (error){
        _this.overlay = false;
        console.log(error)
      })
    },
    async checkLive(){
      let _this = this;
      _this.overlay = true;

      let liveStreamGetOrderLiveStreamPlayerLink = new LiveStreamGetOrderLiveStreamPlayerLink(_this);
      liveStreamGetOrderLiveStreamPlayerLink.setParams({orderId:_this.orderId});
      await liveStreamGetOrderLiveStreamPlayerLink.fetch(function (content){
        _this.overlay = false;
        _this.video = content.data
        if (!_this.video.hlsUrl){
          return _this.$toast({
            component: ToastificationContent,
            position: 'bottom-center',
            props: {
              title: `Sorry`,
              icon: 'SlashIcon',
              variant: 'danger',
              text:'Live is not ready yet,please retry in 10 seconds.'
            },
          })
        }else {
          _this.$refs.modalBtn.click();
        }
      },function (error){
        _this.overlay = false;
        console.log(error)
      })
    },
    async sendPaymentDetailByCustomer(){
      let _this = this;
      _this.overlay = true;

      let formData = Helper.makeFormData(_this.paymentDetailByCustomer);
      let upFile = _this.$refs.refInputEl.files[0];
      formData.append('Files', upFile);
      let orderPaymentDoneByUserMilestone = new OrderPaymentDoneByUserMilestone(_this);
      orderPaymentDoneByUserMilestone.setParams(formData);
      await orderPaymentDoneByUserMilestone.sendFormData((content) => {
        _this.overlay = false;
        _this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            title: `Operation successful`,
            icon: 'CheckIcon',
            variant: 'success',
          },
        })
        window.location.reload();
      }, (error) => {
        _this.overlay = false;
        console.log(error)
      });
    },
    makePrettyPrice(param){
      return Helper.prettyPrice(param,0)
    },
    createJalaliDate(param){
      return new Date(param).toLocaleDateString("en-US",);
    },
    btnChecker(s){
      if (this.order.orderStatus === s){
        return 'btn-warning'
      }
      if(this.order.orderStatus >= s){
        return 'btn-primary'
      }
    },
    textChecker(s){
      if (this.order.orderStatus === s){
        return 'text-warning'
      }
      if(this.order.orderStatus >= s){
        return 'text-primary'
      }
    },
    setCover(e) {
      let file = e.target.files[0];
      this.base64ImageSrc = URL.createObjectURL(file);
    },
    downloadPicture(){
      let link = document.createElement("a");
      // If you don't know the name or want to use
      // the webserver default set name = ''
      link.setAttribute('download', 'test');
      link.setAttribute('target', '_blank');
      link.href = this.baseUrl+this.picture;
      document.body.appendChild(link);
      link.click();
      link.remove();
    }
  },
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BOverlay,
    BFormGroup,
    vSelect,
    BFormTextarea,
    BAlert
  },
}
</script>

<style>
.stepwizard-step p {
  margin-top: 10px;
}
.stepwizard-row {
  display: table-row;
}
.stepwizard {
  display: table;
  width: 50%;
  position: relative;
}
.stepwizard-step button[disabled] {
  opacity: 1 !important;
  filter: alpha(opacity=100) !important;
}
.stepwizard-row:before {
  top: 14px;
  bottom: 0;
  position: absolute;
  content: " ";
  width: 100%;
  height: 1px;
  background-color: #ccc;
  z-order: 0;
}
.stepwizard-step {
  display: table-cell;
  text-align: center;
  position: relative;
}
.btn-circle {
  width: 30px;
  height: 30px;
  text-align: center;
  padding: 6px 0;
  font-size: 12px;
  line-height: 1.428571429;
  border-radius: 15px;
  border: 1px solid #676d7d;
  background: #cccccc;
}
.bg-blue {
  border-color: #006699!important;
  background-color: #006699;
  color: white;
}
</style>